<template>
  <div class="NewUsers">
      <div class="NewUsers-tit">
          <h2>创建子账号</h2>
      </div>
      <el-divider content-position="left">新建子账号基本信息</el-divider>
      <div class="myDeputyBasicsMsg">
          <div class="basicsMsg">
              <form action="" id="newUserMsg">
                <el-row>
                    <el-col :span="4">
                        <label for="">用户名<span>*</span> </label>
                    </el-col>
                    <el-col :span="20">
                        <el-input placeholder="请输入用户名" id="username" name="username" v-model="username" clearable></el-input>
                        <p class="error" v-show="usernameShow">
                            <span class="tip"></span>
                            <span class="info">请输入用户名</span>
                        </p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                         <label for="">密码<span>*</span></label>
                    </el-col>
                    <el-col :span="20">
                        <el-input placeholder="请输入密码*" id="password" name="password" v-model="password" clearable show-password></el-input>
                        <p class="error"  v-show="passwordShow">
                            <span class="tip"></span>
                            <span class="info">请输入密码</span>
                        </p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                         <label for="">公司名称<span>*</span></label>
                    </el-col>
                    <el-col :span="20">
                        <el-input placeholder="请输入公司名称" id="company_name" name="company_name" v-model="company_name" clearable></el-input>
                        <p class="error" v-show="companyNameShow">
                            <span class="tip"></span>
                            <span class="info">请输入公司名称</span>
                        </p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                         <label for="">邮箱<span>*</span></label>
                    </el-col>
                    <el-col :span="20">
                         <el-input placeholder="请输入正确的邮箱" id="email" name="email" v-model="email" @input='checkemail(email)' clearable></el-input>
                        <p class="error" v-show="emailShow">
                            <span class="tip"></span>
                            <span class="info">请输入正确的邮箱</span>
                        </p>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4">
                         <label for="">手机号<span>*</span></label>
                    </el-col>
                    <el-col :span="20">
                        <el-input placeholder="请输入正确的手机号" name="phone" id="phone" v-model="phone" @input="checkPhone(phone)" clearable></el-input>
                        <p class="error" v-show="phoneShow">
                            <span class="tip"></span>
                            <span class="info">请输入正确的手机号</span>
                        </p>
                    </el-col>
                </el-row>
                <el-row class="btnn">
                    <el-col :span="24">
                        <button type="button" class="btn" @click="createAssistant">新建公司子账号</button>
                    </el-col>
                </el-row>
              </form>
          </div>
      </div>
       <el-divider content-position="left">子账号申请</el-divider>
       <div class="application">
            <el-table
                v-if="applicationList.length > 0"
                :data="applicationList"
                border
                style="width: 100%">
                <el-table-column
                prop="username"
                label="申请人">
                </el-table-column>
                <el-table-column
                prop="phone"
                label="手机号">
                </el-table-column>
                <el-table-column
                prop="email"
                label="邮箱">
                </el-table-column>
                <el-table-column
                prop="create_time"
                label="时间">
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button type="success" size="small" @click="deal(scope.row.id,1)">同意</el-button>
                        <el-button type="danger" size="small" @click="deal(scope.row.id,2)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page" v-if="applicationList.length > 0">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count">
                </el-pagination>
            </div>
            <img v-if="applicationList.length == 0" src="../../assets/null.png" alt="">
       </div>
      <el-divider content-position="left">所属子账号</el-divider>
      <div class="belongs" v-if="assistantList.length>0">
          <el-table
                :data="assistantList"
                border
                style="width: 100%">
                <el-table-column
                prop="username"
                label="用户名">
                </el-table-column>
                <el-table-column
                label="状态">
                 <template slot-scope="scope">
                    <span>{{ scope.row.status == 0 ? '未通过' :scope.row.status == 3 ? '待审核': '审核通过' }}</span>
                </template>
                </el-table-column>
                <el-table-column
                prop="email"
                label="邮箱">
                </el-table-column>
                 <el-table-column
                prop="phone"
                label="手机号">
                </el-table-column>
            </el-table>
      </div>
      <div class="null" v-else>
          <img src="../../assets/null.png" alt="">
      </div>
  </div>
</template>

<script>
    import NewUsers from './NewUsers.js'
    export default NewUsers
</script>

<style scoped>
    @import './NewUsers.css';
</style>