import NewUsers from './NewUsers.vue'
import axios from 'axios'
import jwt_decode from "jwt-decode";
export default {
    commponent:{
        NewUsers
    },
    data(){
        return {
            username:'',
            password:'',
            company_name:'',
            email:'',
            phone:'',
            usernameShow:false,
            passwordShow:false,
            companyNameShow:false,
            emailShow:false,
            phoneShow:false,
            assistantList:[],
            applicationList:[],
            page:1,
            per_page:10,
            currentPage:1,
            count:0,
        }
    },
    created(){
       this.searchAssistant() 
       this.applicationRecord()
    },
    methods:{
        // 验证邮箱
        checkemail(val){
            var re = /^[a-zA-Z0-9_-_.]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            var objExp = new RegExp(re)
            if(objExp.test(val) == true){
                this.emailShow = false
            }else{
                this.emailShow = true
            }
        },
        // 验证手机号
        checkPhone(val) {
            var myreg = /(^1|^84)(\d{10}|\d{9})$/;
            if(!myreg.test(val)) {
                this.phoneShow = true;
            } else {
                this.phoneShow = false;
            }
        },
        // 创建副账号
        createAssistant(){
            if(this.username == ''){
                this.usernameShow = true
            }else if(this.password == ''){
                this.usernameShow = false
                this.passwordShow = true
            }else if(this.company_name == ''){
                this.passwordShow = false
                this.companyNameShow = true
            }else if(this.email == ''){
                this.companyNameShow = false
                this.emailShow = true
            }else if(this.phone == ''){
                this.emailShow = false
                this.phoneShow = true
            }else {
                this.phoneShow = false
                var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
                var newUserMsg = document.getElementById('newUserMsg')
                var fd = new FormData(newUserMsg)
                fd.append('admin_uid',decoded.uid)
                axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
                axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
                axios.post(this.GLOBAL.BASE_URL1 + '/user/assistant',fd)
                .then(res=>{
                    if(res.data.status_code == 0){
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'success',
                            center: true
                        });
                        this.searchAssistant()
                    }else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'error',
                            center: true
                        });
                    }
                })
                .catch(error=>{
                    console.log(error);
                })
            }
        },
        // 副账号查询
        searchAssistant(){
            var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/user/assistant/'+ decoded.uid,{
                params:{}
            })
            .then(res=>{
                // console.log(res);
                if(res.data.status_code == 0){
                    this.assistantList = res.data.data
                }else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'error',
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 副账号申请记录
        applicationRecord(){
            axios.get(this.GLOBAL.BASE_URL1 + '/user/application_record',{
                params:{
                    per_page:this.per_page,
                    page:this.page
                }
            })
            .then(res=>{
                console.log(res);
                if(res.status == 200){
                    this.applicationList = res.data.data
                    this.count = res.data.count
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.per_page = val
            this.applicationRecord()
          },
          handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val
            this.applicationRecord()
          },
        // 副号申请处理
        deal(uid,status){
            var fd = new FormData()
            fd.append('second_uid',uid)
            fd.append('status',status)
            axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8';
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.post(this.GLOBAL.BASE_URL1 + '/user/application_record',fd)
            .then(res=>{
                console.log(res);
                if(res.data.status_code == 0){
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'success',
                        center: true
                    });
                    this.applicationRecord()
                    this.searchAssistant()
                }else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'error',
                        center: true
                    });
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
    }
}